import * as React from "react"
import "./newsdetailcontent.scss"
import { createMarkup,string_translation } from "../../../utils"
import {  useStaticQuery, graphql } from "gatsby"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

export default function WorkZoneDetail({data}) {
  const {
    locale,
    originalUrl, // ar
  } = useContext(TranslateUrlsContext)
  const { stringTranslations } = useStaticQuery(graphql`
    query {
      stringTranslations {
        workzone_ar:translateString(language: AR, string: "الأعمال الإنشائية")
        workzone_en:translateString(language: EN, string: "Work Zone")
      }
    }
  `)
  return (
    <>
      <section className="news-info-work-zone">
        <div className="container">
          <div className="col-group">
            <div className="col-dt-4 left-col">
              <div className="big-title h2">
                {data.title}
              </div>
              <div className="date-tag">
                      {string_translation(stringTranslations, "workzone", locale)}
                      </div>
              <div className="date">{data.date}</div>
              
            </div>
            <div className="col-dt-8 right-col" dangerouslySetInnerHTML={createMarkup(data.content)}></div>
          </div>
         
        </div>
      </section>
    </>
  )
}
