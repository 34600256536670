import * as React from "react"
import { useContext } from "react"
import "./newsdetail.scss"
import { Link } from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import {useStaticQuery, graphql} from "gatsby"
import { string_translation } from "../../../utils"

export default function WorkZoneDetailsFirst(props) {

  const {
    locale, // ar
    locales, // ["en", "ar"]
    originalUrl, // "/about-us"
    translateUrl, // (url(, locale)) => translatedUrl
  } = useContext(TranslateUrlsContext)
  const {stringTranslations} = useStaticQuery(graphql`query {
    stringTranslations {
      backWorkZone_ar:translateString(language: AR, string: "العودة إلى صفحة الأعمال الإنشائية")
      backWorkZone_en:translateString(language: EN, string: "Back to Work Zone Page​")
      
    }
  }`)
  return (
    <>
      <section className="news-landing">
        <div className="container">
          <Link to={props.parentUrl} className="get-back-anchor">
            <div className="get-back">
              <img src="/images/news/arrow.svg" alt="Arrow" />
              <div className="text">{string_translation(stringTranslations,"backWorkZone", locale)}</div>
            </div>
          </Link>
          <div className="news-img desktop-show">
            <img src={props.image?.localFile?.publicURL} alt={props.image?.altText || "Workzone Area Updates"} />
          </div>
          <div className="news-img mobile-show">
            <img src={props.image?.localFile?.publicURL} alt={props.image?.altText || "Workzone Area Updates"} />
          </div>
        </div>
      </section>
    </>
  )
}
