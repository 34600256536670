import * as React from 'react'
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import { graphql, Link, useStaticQuery } from "gatsby"
import "../styles/style.scss"
import "../styles/css/swiper-bundle.min.css"
import Layout from '../components/layout'
import Seo from '../components/seo'
import NewsDetailFirst from '../components/FirstNewsDetail'
import NewsContentDetail from '../components/NewsContentDetail'
import RelatedNews from '../components/RelatedNews'
import Twitter from '../components/Twitter'
import CommunityActivityFirstComponent from '../components/CommunityActivityFirstComponent'
import WhoWeAre from '../components/WhoWeAre'
import ComboWrapperCICActivity from '../components/ComboWrapperCICActivity'
import WorkZoneDetailsFirst from '../components/FirstWorkZoneDetail'
import WorkZoneDetail from '../components/WorkZoneDetail'
import LatestWorkZones from '../components/LatestWorkZones'
import RelatedWorkZones from '../components/RelatedWorkZones'

export default function WorkZoneDetails({pageContext, location}) {
  const data  = useStaticQuery(graphql`{
    en: allWpWorkzone(
      sort: {order: DESC, fields: databaseId}
      filter: {status: {eq: "publish"}, language: {code: {eq: EN}}}
    ) {
      edges {
        node {
          language {
            id
            locale
          }
          id
          title
          
          excerpt
          date(formatString: "DD-MM-YYYY")
          featuredImage {
            node {
              localFile {
                publicURL
              }
            }
          }
         content
          slug
          uri
          translations {
            uri
          }
        }
      }
    }
    ar: allWpWorkzone(
      sort: {order: DESC, fields: databaseId}
      filter: {status: {eq: "publish"}, language: {code: {eq: AR}}}
    ) {
      edges {
        node {
          language {
            id
            locale
          }
          id
          title
          
          excerpt
          date(formatString: "DD-MM-YYYY")
          featuredImage {
            node {
              localFile {
                publicURL
              }
            }
          }
         content
          slug
          uri
          translations {
            uri
          }
        }
      }
    }
  }`);
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  let mainUrl= location.state?.parenturl
  let seo = pageContext?.node?.node?.seo
  let translatedURI = pageContext?.node?.node?.translations[0]?.uri
  if(!mainUrl){
     mainUrl = "/"+locale
  }

    return (
        <>
        <Seo data={pageContext?.node?.node}/>
        <Layout translatedURI={translatedURI}>
        <WorkZoneDetailsFirst image={pageContext?.node?.node?.featuredImage?.node} parentUrl={mainUrl}/>
        <WorkZoneDetail data={pageContext?.node?.node}/>
       
        {locale === "en" ? (
      
          <RelatedWorkZones data={data?.en?.edges}/>
          
        ) : (
    
            <RelatedWorkZones data={data?.ar?.edges}/>
            

        )} 
        </Layout>
        </>
    )
}
